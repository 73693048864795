// /**
//  * ImageUploader.js - a client-side image resize and upload javascript module
//  *
//  * @author Ross Turner (https://github.com/zsinj)
//  *
//  * Test
//  * 1. With no watermark configured
//  * 2. With text watermark
//  * 3. With Image watermark
//  * 4. With Add Watermark settings OFF
//  * 5. Cancel upload in between
//  * 6. Upload with all duplicates
//  * 7. Upload with some duplicates
//  * 8. Upload with no duplicates
//  * 9. Upload more than 1 batch ( > UploadBatchSize)
//  *
//  */

import ls from 'local-storage';
import Photo from '../pouchDB/models/Photo';
import Push from '../pouchDB/models/Push';
import Watermark from '../pouchDB/models/Watermark';
import Resize from './Resize';
import { atPosWithSize, getImageFromWatermarkText } from './watermark';

const UploadBatchSize = 50;
const DEFAULT_IMAGE_RESOLUTION = 2560;

class ImageUploader {
	getBase64Image = (base64Image) => {
		return new Promise((resolve, reject) => {
			const image = document.createElement('img');
			image.onload = (e) => {
				resolve(image);
			};
			image.onerror = (e) => {
				reject(e);
			};
			image.src = base64Image;
		});
	};

	setOnProgressListener = (onProgress) => {
		this.onProgressListener = onProgress;
	};

	cancel = () => {
		console.log('Cancellation requested.');
		this.isCancelled = true;
	};

	validateSubscriptionLimits = (config, uploadCount) => {
		const subscription = config.subscription;
		if (!subscription) return false;

		// Check subscription is active
		const isActive = subscription.status === 'active';
		const isExpired = new Date(subscription.expiresAt) <= new Date();

		if (!isActive || isExpired) {
			return {
				valid: false,
				message: 'Subscription is not active or has expired'
			};
		}

		// Check upload limit
		const hasUploadLimit =
			subscription.uploadedPhotosCount + uploadCount <= subscription.uploadLimit;
		if (!hasUploadLimit) {
			return {
				valid: false,
				message: `Upload limit reached. Your subscription allows ${subscription.uploadLimit} photos total.`
			};
		}

		// Check max photos limit
		const hasMaxLimit =
			subscription.currentPhotosCount + uploadCount <= subscription.maxPhotosLimit;
		if (!hasMaxLimit) {
			return {
				valid: false,
				message: `Storage limit reached. Your subscription allows ${subscription.maxPhotosLimit} photos total.`
			};
		}

		return {
			valid: true
		};
	};

	validatePackBasedLimits = (config, uploadCount) => {
		if (config.eventPhotoCount < 0) return { valid: true };

		const totalLimit = config.event.maxPhotos - config.event.guestMaxPhotos;
		const remaining = totalLimit - config.eventPhotoCount;

		if (config.eventPhotoCount + uploadCount > totalLimit) {
			return {
				valid: false,
				message:
					remaining <= 0
						? 'Photos limit exceeded.'
						: `Photos limit exceeded. You can only upload ${remaining} more photos`
			};
		}

		return { valid: true };
	};

	uploadFileFromFileArray = async (fileArray) => {
		this.isCancelled = false;
		this.duplicateIgnored = false;
		const config = this.config;

		let uniqueIds = fileArray.map((file) => Photo.generatePhotoId(config.event.channel, file));
		this.pendingItems = fileArray.length;

		try {
			// Check for duplicates first
			const filtered = await Photo.filterDuplicates(config.userId, config.event.id, uniqueIds);
			if (filtered.length === 0) {
				this.onProgressListener(
					'complete',
					this.pendingItems,
					'All Photos are already present in this Event. Not uploaded.'
				);
				return;
			}

			// Validate limits based on event type
			const validation =
				config.event.eventType === 1
					? this.validateSubscriptionLimits(config, filtered.length)
					: this.validatePackBasedLimits(config, filtered.length);

			if (!validation.valid) {
				this.onProgressListener('error', this.pendingItems, validation.message);
				return;
			}

			// Filter out duplicates if needed
			let uploadFiles =
				filtered.length === fileArray.length
					? fileArray
					: fileArray.filter((file) => {
							let photoDocId = Photo.generatePhotoId(config.event.channel, file);
							return filtered.includes(photoDocId);
					  });

			this.duplicateIgnored = uploadFiles.length < fileArray.length;
			await this.checkWatermarkAndUploadFiles(config, uploadFiles);
		} catch (err) {
			console.error('Upload failed:', err);
			this.onProgressListener(
				'error',
				this.pendingItems,
				err.response?.body?.message || 'Upload failed.'
			);
		}
	};

	checkWatermarkAndUploadFiles = async (config, fileArray) => {
		let addWatermark = ls.get('addWatermarkDuringUpload') !== '0';

		let watermarkOptions = undefined;
		if (addWatermark) {
			try {
				const watermark = await Watermark.getWatermark(config.userId);
				console.log('Watermark:', watermark);

				let watermarkImage = undefined;
				if (watermark.base64Image) {
					console.log('Adding image watermark');
					watermarkImage = await this.getBase64Image(watermark.base64Image);
				} else if (watermark.title) {
					console.log('Adding text watermark');
					watermarkImage = await getImageFromWatermarkText(watermark.title, watermark.subTitle);
				} else {
					console.log('Watermark found but no title. Uploading without it.');
				}

				if (watermarkImage) {
					watermarkOptions = {
						image: watermarkImage,
						position: watermark.position,
						size: watermark.size
					};
				}
			} catch (err) {
				if (err.status === 404) {
					console.log('Watermark not configured. Uploading without it.');
				} else {
					throw err;
				}
			}
		}

		await this.processAndUploadFiles(config, fileArray, watermarkOptions);
	};

	processAndUploadFiles = async (config, fileArray, watermark) => {
		this.onProgressListener('active', this.pendingItems);
		console.time('AllUploads');

		// Split into batches
		const batches = [];
		const totalBatches = Math.ceil(fileArray.length / UploadBatchSize);
		for (let i = 0; i < totalBatches; i++) {
			const batch = fileArray.slice(i * UploadBatchSize, (i + 1) * UploadBatchSize);
			if (batch.length > 0) {
				batches.push(batch);
			}
		}

		// Process each batch
		for (const batch of batches) {
			if (this.isCancelled) {
				console.log('Upload cancelled by user');
				break;
			}
			await this.runBatch(config, batch, watermark);
		}

		console.timeEnd('AllUploads');

		// Send push notification
		if (!this.isCancelled) {
			await Push.sendPush(config.userId, config.event, config.album);

			const message = this.duplicateIgnored
				? 'Upload complete! Duplicate photos were not uploaded.'
				: 'Upload complete!';

			this.onProgressListener('complete', this.pendingItems, message);
		}
	};

	runBatch = async (config, fileArray, watermark) => {
		for (const file of fileArray) {
			if (this.isCancelled) {
				console.log('Batch cancelled. Skipping remaining files.');
				return;
			}

			try {
				const resizeResult = await this.resizeImage(config, file, watermark);
				await this.uploadImage(config, resizeResult);

				this.pendingItems--;
				this.onProgressListener('active', this.pendingItems);
			} catch (error) {
				console.error('Failed to process file:', file.name, error);
				// Continue with next file
			}
		}
	};

	resizeImage = async (config, file, watermark) => {
		console.log('Resizing:', file.name);
		const resize = new Resize(config.debug, config.quality);

		let watermarkContextModifier = undefined;
		if (watermark?.image) {
			console.log('Applying watermark');
			watermarkContextModifier = atPosWithSize(watermark.image, watermark.position, watermark.size);
		}

		const resizeResult = await resize.resizeAndGetThumbnail(
			file,
			DEFAULT_IMAGE_RESOLUTION,
			watermarkContextModifier
		);

		return {
			file,
			resizedImage: resizeResult.resizedImage,
			clickedAt: resizeResult.clickedAt
		};
	};

	uploadImage = async (config, result) => {
		console.time(`Upload ${result.file.name}`);
		console.log('Uploading:', result.file.name);

		try {
			const photoDocId = Photo.generatePhotoId(config.event.channel, result.file);
			const photoToUpload = Photo.createPhotoToUpload(
				photoDocId,
				result.file,
				config.userId,
				config.event.id,
				config.album.id,
				result.clickedAt
			);

			await Photo.uploadPhotos(photoToUpload, result.resizedImage);
			console.timeEnd(`Upload ${result.file.name}`);
		} catch (error) {
			console.error(`Failed to upload ${result.file.name}:`, error);
			throw error;
		}
	};

	setConfig = function (customConfig) {
		this.config = customConfig;
	};
}

export default ImageUploader;
